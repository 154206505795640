import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-48f67505"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "c-card__heading-row" }
const _hoisted_2 = {
  key: 0,
  class: "icon-warning-circle",
  "aria-hidden": "true"
}
const _hoisted_3 = { class: "c-card__title" }
const _hoisted_4 = { class: "c-card__units" }
const _hoisted_5 = { class: "c-card__description" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ScoreBar = _resolveComponent("ScoreBar")!
  const _component_AccordionTab = _resolveComponent("AccordionTab")!
  const _component_Accordion = _resolveComponent("Accordion")!

  return (_openBlock(), _createBlock(_component_Accordion, null, {
    default: _withCtx(() => [
      _createVNode(_component_AccordionTab, null, {
        header: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            (_ctx.isRedColor)
              ? (_openBlock(), _createElementBlock("i", _hoisted_2))
              : _createCommentVNode("", true),
            _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.$t(`cgm-scores.${_ctx.score.type}`)), 1),
            _createElementVNode("span", {
              class: _normalizeClass(["c-card__score", _ctx.measurementColor])
            }, [
              _createTextVNode(_toDisplayString(_ctx.score.value != null ? _ctx.score.value : '--') + " ", 1),
              _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.measurementUnit), 1)
            ], 2)
          ])
        ]),
        default: _withCtx(() => [
          _createElementVNode("p", _hoisted_5, _toDisplayString(_ctx.scoreDescription), 1),
          _createVNode(_component_ScoreBar, { score: _ctx.score }, null, 8, ["score"])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}